import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Cabin1, Cabin2, Cabin3, Cabin4 } from "./assets/images";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Carousel 
        infiniteLoop 
        showThumbs={false}
        stopOnHover={false}
        centerSlidePercentage={100}
        centerMode
        autoPlay 
        interval={3500}
        transitionTime={1000}
        showStatus={false}
      >
        <div>
          <img src={Cabin1} />
          {/* <p className="legend">Legend 1</p> */}
        </div>
        <div>
          <img src={Cabin2} />
          {/* <p className="legend">Legend 2</p> */}
        </div>
        <div>
          <img src={Cabin3} />
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={Cabin4} />
          {/* <p className="legend">Legend 3</p> */}
        </div>
      </Carousel>
    </div>
  );
}

export default App;
